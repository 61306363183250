<template>
  <section class="container">
    <div class="relative flex text-clip max-md:flex-col-reverse md:rounded-3xl">
      <div class="relative z-10 size-full max-w-[500px] rounded-xl bg-white max-md:mt-6 md:m-8 md:p-10 lg:m-12">
        <Badge
          label="Drivers Wanted!"
          background-color="transparent"
          text-color="text-green-600" />
        <h2
          class="mt-4 text-4xl font-bold uppercase text-green-500 md:text-5xl lg:text-6xl">
          Earn money with your ute or van.
        </h2>
        <p
          class="mt-4 text-xl font-light md:text-2xl">
          Drive with PicUp and earn $60+ per hour, or $120+ per hour as part of a two-person team. To get started, all you need is your own ute or van,
          insurance, and to live within our supported regions.
        </p>
        <NuxtLink to="/apply-to-drive">
          <Button
            class="mt-8 lg:mt-24">
            Apply Now
          </Button>
        </NuxtLink>
      </div>

      <NuxtImg
        src="/images/hauler-two.jpg"
        quality="75"
        loading="lazy"
        sizes="100vw md:1400px"
        class="z-0 size-full rounded-3xl object-cover object-right md:object-center lg:absolute"
        alt="hauler" />
    </div>
  </section>
</template>
